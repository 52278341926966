import jr_headshot from "../../assets/organizer-imgs/jr_headshot.jpg";
import MaridyMazaira from "../../assets/organizer-imgs/MaridyMazaira.jpeg";
import JamalLongwood from "../../assets/organizer-imgs/JamalLongwood.jpeg";


const organizersData = [
    {
        imageurl: jr_headshot,
        name: "Jenna Ritten",
        position: "Chief Architect & Chief Developer Advocate",
    },
    {
        imageurl: MaridyMazaira,
        name: "Maridy Mazaira",
        position: "Staff Engineer - DTE Energy",
    },
    {
        imageurl: JamalLongwood,
        name: "Jamal Longwood",
        position: "Thermal Development Engineer",
    },
]
export default organizersData;